import axios from "axios";

// Make some default configuration
axios.defaults.baseURL =
  window.location.hostname === "localhost"
    ? "http://localhost:8081/api/v1/"
    : "//api.tajalkarama.com/api/v1/";
axios.defaults.headers.common["x-app-token"] = "KaramaToken";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "tester_token"
)}`;
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

let front_url =
  window.location.hostname === "localhost"
    ? "http://localhost:8081/"
    : "//api.tajalkarama.com/";

// Export request services
const axiosMoudle = {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  patch: axios.patch,
  front_url,
};
export default axiosMoudle;
