import React from "react";
import { withRouter } from "react-router-dom";
import Http from "../../services/Http";
import {
  LoadingOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Checkbox, Popconfirm } from "antd";
import HijriDatePicker from "../../helpers/hijri-date-picker/src/lib";
import moment from "moment-hijri";
moment.locale("ar-SA");

const UpdateType = (props) => {
  // define state
  const [student, setStudent] = React.useState(null);
  const [teacher, setTeacher] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [test, setTest] = React.useState(null);
  const [newQuestions, setNewQuestions] = React.useState([]);
  const [reviewQuestions, setReviewQuestions] = React.useState([]);
  const [tajweed, setTajweeed] = React.useState([]);
  const [rates, setRates] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [degree, setDegree] = React.useState(0);
  const [publicQuest, setPublicQuest] = React.useState([]);
  const [notes, setNotes] = React.useState("");
  const [nextTestDate, setNextTestDate] = React.useState();

  const [errors, setErrors] = React.useState({});
  const [btnLoad, setBtnLoad] = React.useState(false);

  React.useEffect(() => {
    getType();
  }, []);

  const getType = async (req, res) => {
    Promise.all([
      await Http.get("testers/tests/" + props.match.params.id),
      await Http.get("testers/rates"),
    ]).then((res) => {
      setStudent(res[0].data.test.student);
      setTeacher(res[0].data.test.teacher);
      setPlan(res[0].data.test.plan);
      setTest(res[0].data.test);
      setNewQuestions(res[0].data.test?.questions?.news);
      setReviewQuestions(res[0].data.test?.questions?.reviews);
      setTajweeed(res[0].data.test?.questions?.tajweed);
      setPublicQuest(res[0].data.test?.questions?.others);
      setRates(res[1].data.rates);
      setTotal(res[0].data.test.total);
      setDegree(res[0].data.test.degree);
      setNotes(res[0].data.test.notes);
    });
  };

  const addPublicQuestions = (event) => {
    if (publicQuest.length === test?.type["others"]?.count)
      openNoti(
        "warning",
        "ملاحظة هامة",
        "لقد تعديت العدد المسموح به من هذا النوع من الاختباارت"
      );
    else {
      if (!publicQuest.find((d) => d.name === event.target.value)) {
        let target = rates
          .find((r) => r.code === 3)
          .details.find((d) => d.name === event.target.value);
        let obj = {
          name: target.name,
          degree: test?.type["others"]?.degree,
        };
        setPublicQuest([...publicQuest, obj]);
        setDegree(degree + Number(test?.type?.others?.degree));
        setTotal(total + Number(test?.type?.others?.degree));
      }
    }
  };
  const addTajweedQuestions = (event) => {
    if (
      tajweed.length === test?.type["tajweed"]?.count ||
      (event.target.value.includes("-") &&
        event.target.value.split("-").length + tajweed.length >
          test?.type["tajweed"]?.count)
    )
      openNoti(
        "warning",
        "ملاحظة هامة",
        "لقد تعديت العدد المسموح به من هذا النوع من الاختباارت"
      );
    else {
      if (event.target.value.includes("-")) {
        event.target.value.split("-").map((item) => {
          addTajweedQuest(item);
        });
        setDegree(
          degree +
            Number(test?.type?.tajweed?.degree) *
              event.target.value.split("-").length
        );
        setTotal(
          total +
            Number(test?.type?.tajweed?.degree) *
              event.target.value.split("-").length
        );
      } else {
        addTajweedQuest(event.target.value);
        setDegree(degree + Number(test?.type?.tajweed?.degree));
        setTotal(total + Number(test?.type?.tajweed?.degree));
      }
    }
  };

  const addTajweedQuest = (QuestName) => {
    let taj = tajweed;
    if (!tajweed.find((d) => d.name === QuestName)) {
      let obj = {
        name: QuestName,
        degree: test?.type["tajweed"]?.degree,
      };

      taj.push(obj);
      setTajweeed(taj);
      setDegree(degree + Number(test?.type?.tajweed?.degree));
      setTotal(total + Number(test?.type?.tajweed?.degree));
    }
  };

  const removePublicQuest = (index, name) => {
    setPublicQuest(publicQuest.filter((d, i) => i !== index));
    setDegree(degree - publicQuest[index].degree);
    setTotal(total - Number(test?.type?.others?.degree));
  };

  const removeTajweed = (index) => {
    setTajweeed(tajweed.filter((d, i) => i !== index));
    setDegree(degree - tajweed[index].degree);
    setTotal(total - Number(test?.type?.tajweed?.degree));
  };

  const getCategory = (category) => {
    switch (category) {
      case 1:
        return "جديد";
      case 2:
        return "إعادة أ";
      case 3:
        return "إعادة ب";

      default:
        break;
    }
  };
  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "مفتوح";
      case 2:
        return "تم التحضير";
      case 3:
        return "منتهي";

      default:
        break;
    }
  };

  const handleQuestions = (method, type, index, value) => {
    let clone = type === "news" ? [...newQuestions] : [...reviewQuestions];

    if (method === "add") {
      clone[index].text = value;
      type === "news" ? setNewQuestions(clone) : setReviewQuestions(clone);
    } else {
      let final = total;
      let finalDegree = degree;
      setTotal(final - test?.type?.[type]?.degree);
      setDegree(finalDegree - clone[index].degree);
      type === "news"
        ? setNewQuestions(clone.filter((c, i) => i !== index))
        : setReviewQuestions(clone.filter((c, i) => i !== index));
    }
  };

  const handleOthers = (index, name, num, status) => {
    let clone = [...publicQuest];
    if (status) {
      clone[index].degree = test?.type?.others?.degree;
      setDegree(degree + num);
    } else {
      clone[index].degree = 0;
      setDegree(degree - num);
    }
  };

  const handleTajweed = (index, num, status) => {
    let clone = [...tajweed];
    if (status) {
      clone[index].degree = test?.type?.tajweed?.degree;
      setDegree(degree + num);
    } else {
      clone[index].degree = 0;
      setDegree(degree - num);
    }
  };

  const handleAddQuestion = (type) => {
    let clone = type === "news" ? [...newQuestions] : [...reviewQuestions];
    if (clone.length === test?.type?.[type]?.count)
      openNoti(
        "warning",
        "ملاحظة هامة",
        "لقد تعديت العدد المسموح به من هذا النوع من الاختباارت"
      );
    else {
      setTotal(total + test?.type?.[type]?.degree);
      setDegree(degree + test?.type?.[type]?.degree);
      type === "news"
        ? setNewQuestions([
            ...newQuestions,
            { text: "", degree: test?.type?.news?.degree },
          ])
        : setReviewQuestions([
            ...reviewQuestions,
            { text: "", degree: test?.type?.reviews?.degree },
          ]);
    }
  };

  const minus = (type, index, points, name, value) => {
    let clone = type === "news" ? [...newQuestions] : [...reviewQuestions];

    let minusNum =
      clone[index].degree || clone[index].degree == 0
        ? clone[index].degree
        : points;
    let final = minusNum - Number(value);
    if (final >= 0) {
      clone[index].degree = clone[index].degree
        ? clone[index].degree - Number(value)
        : points - Number(value);

      type === "news" ? setNewQuestions(clone) : setReviewQuestions(clone);
      final = degree - Number(value);
      if (final >= 0) setDegree(degree - Number(value));
      else openNoti("warning", "ملاحظة هامة", "الدرجة سوف تصبح بالسالب");

      // set mistakes
      if (clone[index].mistakes?.length) {
        if (clone[index].mistakes?.find((mistake) => mistake.name === name)) {
          clone[index].mistakes.find((mistake) => mistake.name === name)
            .count++;
        } else {
          clone[index].mistakes.push({ name, count: 1 });
        }
      } else {
        clone[index].mistakes = [{ name, count: 1 }];
      }
    } else
      openNoti(
        "warning",
        " ملاحظة هامة",
        "الدرجة سوف تصبح بالسالب فى هذا السؤال"
      );
  };

  const undo = (type, index, points, name, value) => {
    // undo mistake
    let clone = type === "news" ? [...newQuestions] : [...reviewQuestions];
    if (clone[index].mistakes.find((m) => m.name === name)) {
      let minusNum =
        clone[index].degree || clone[index].degree == 0
          ? clone[index].degree
          : points;

      let final = minusNum + Number(value);

      if (final <= points) {
        clone[index].degree =
          clone[index].degree || clone[index].degree == 0
            ? clone[index].degree + Number(value)
            : points + Number(value);

        if (clone[index].mistakes.find((m) => m.name === name).count === 1) {
          clone[index].mistakes = clone[index].mistakes.filter(
            (m) => m.name !== name
          );
        } else {
          clone[index].mistakes.find((m) => m.name === name).count--;
        }

        type === "news" ? setNewQuestions(clone) : setReviewQuestions(clone);
        final = degree + Number(value);
        if (final <= total) setDegree(degree + Number(value));
        else
          openNoti(
            "warning",
            "ملاحظة هامة",
            "الدرجة سوف تصبح اكبر من الدرجة النهائية"
          );
      }
    } else
      openNoti(
        "warning",
        "ملاحظة هامة",
        "الطالب ليس لديه هذا الخطأ لإعادة درجته"
      );
  };

  const saveTest = async (type) => {
    const payload = {
      questions: {
        news: newQuestions,
        reviews: reviewQuestions,
        others: publicQuest,
        tajweed: tajweed,
      },
      notes: notes,
      total: total,
      degree: degree,
    };

    if (type === "fail") {
      await Http.patch(
        "testers/tests/" + props.match.params.id + "?fail=true",
        {
          notes: "لم يتجاوز الاختبار بسبب غياب بدون عذر",
        }
      );
      openNoti(
        "success",
        "تم إنهاء الاختبار",
        "الطالب لم يتجاوز بسبب غياب دون عذر"
      );
    } else {
      try {
        await Http.patch("testers/tests/" + props.match.params.id, payload);
        openNoti("success", "تم التعديل", "تم حفظ الاختبار ");
        // props.history.push("/admin/tests");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const compeleteTest = async () => {
    if (!nextTestDate)
      openNoti("warning", "قم باختيار موعد الاختبار التالى أولا");
    else {
      // save first and finish
      const payload = {
        questions: {
          news: newQuestions,
          reviews: reviewQuestions,
          others: publicQuest,
          tajweed: tajweed,
        },
        notes: notes,
        total: total,
        degree: degree,
      };
      await Http.patch("testers/tests/" + props.match.params.id, payload);
      try {
        await Http.patch(
          "testers/tests/" + props.match.params.id + "/complete",
          { nextTestDate: nextTestDate }
        );
        openNoti("success", "تم الانهاء", "تم إنهاء الاختبار");
        goBack();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <React.Fragment>
      <div className="admin-title">
        <h6>
          الدرجة الحالية <span className="en-font main-color">{degree}</span> من{" "}
          <span className="en-font">{total}</span>
        </h6>
        <div className="admin-title-super2">
          {test?.status === 1 && (
            <button
              type="submit"
              disabled={btnLoad}
              onClick={() => saveTest("fail")}
              className="btn btn-danger form-button ml-3"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> جارى الحفظ ...
                </span>
              ) : (
                "غياب بدون عذر"
              )}
            </button>
          )}
          {test?.status === 1 && (
            <button
              type="submit"
              disabled={btnLoad}
              onClick={() => saveTest()}
              className="btn btn-primary form-button ml-3"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> جارى الحفظ ...
                </span>
              ) : (
                "حفظ"
              )}
            </button>
          )}

          <button onClick={goBack} className="btn btn-warning">
            العودة الى الاختبارات
          </button>
        </div>
      </div>

      <div className="all-categories admin-card pd">
        <div className="test-info">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center">
              <div className="student-avatar  ml-2">
                <img
                  src={
                    student?.avatar
                      ? Http.front_url + student.avatar
                      : "/img/avatar.png"
                  }
                  alt="student"
                  width="70"
                />
              </div>
              <div className="student-info">
                <h6>
                  <span className="main-color">الطالب:</span>{" "}
                  {student?.fullName}
                </h6>
                <h6>
                  <span className="main-color">المعلم:</span>{" "}
                  {teacher?.fullName}
                </h6>
                <h6>
                  <span className="main-color">الخطة:</span> {plan?.name}
                </h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <h6>
                  <span className="main-color">الاعادة:</span>{" "}
                  {getCategory(test?.category)}
                </h6>
                <h6>
                  <span className="main-color">الحالة:</span>{" "}
                  {getStatus(test?.status)}
                </h6>
                <h6>
                  <span className="main-color">النوع:</span> {test?.type?.name}
                </h6>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <h6>
                  <span className="main-color">التاريخ:</span>{" "}
                  {test?.date?.day + " "}{" "}
                  <span className="en-font">
                    {test?.date?.date + " " + test?.date?.time}
                  </span>
                </h6>
                <h6>
                  <span className="main-color">الحفظ:</span>{" "}
                  {test?.details?.new?.name}
                </h6>
                <h6>
                  <span className="main-color">المراجعة:</span>{" "}
                  {test?.details?.review?.name}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة الحفظ</h6>{" "}
            <span className="pr-2 en-font">
              {" "}
              الحد الاقصي هو {test?.type?.news?.count}
            </span>
          </span>
          <button
            type="button"
            onClick={() => handleAddQuestion("news")}
            className="btn btn-primary form-button ml-3"
          >
            +
          </button>
        </div>
        {newQuestions.map((n, index) => (
          <React.Fragment>
            <div className="row align-items-center" key={index}>
              <div className="col-md-1">
                <Input type="number" disabled value={index + 1} />
              </div>
              <div className="col-md-7">
                <Input
                  type="text"
                  placeholder="السؤال؟"
                  value={n.text}
                  onChange={(event) =>
                    handleQuestions("add", "news", index, event.target.value)
                  }
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="number"
                  className="en-font"
                  disabled
                  readOnly
                  value={
                    n.degree || n.degree == 0
                      ? n.degree
                      : test?.type?.news?.degree
                  }
                />
              </div>
              <div className="col">
                <div className="label-header">
                  <label className="custom-label"> </label>
                </div>
                <button
                  className="remove"
                  onClick={() => handleQuestions("remove", "news", index)}
                >
                  <DeleteOutlined />
                </button>
              </div>
            </div>
            <div className="row">
              {rates
                ?.find((r) => r.code === 1)
                ?.details?.map((d, i) => (
                  <div className="col" key={i}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        minus(
                          "news",
                          index,
                          test?.type?.news?.degree,
                          d.name,
                          d.degree
                        )
                      }
                    >
                      {d.name}{" "}
                      <span className="en-font minus-degree">
                        {" - " + d.degree}
                      </span>
                    </button>
                    <button
                      className="btn btn-sm btn-warning mr-2"
                      onClick={() =>
                        undo(
                          "news",
                          index,
                          test?.type?.news?.degree,
                          d.name,
                          d.degree
                        )
                      }
                    >
                      <UndoOutlined />
                    </button>
                  </div>
                ))}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة المراجعة</h6>{" "}
            <span className="pr-2 en-font">
              {" "}
              الحد الاقصي هو {test?.type?.reviews?.count}
            </span>
          </span>
          <button
            type="button"
            onClick={() => handleAddQuestion("reviews")}
            className="btn btn-primary form-button ml-3"
          >
            +
          </button>
        </div>
        {reviewQuestions.map((n, index) => (
          <React.Fragment>
            <div className="row align-items-center" key={index}>
              <div className="col-md-1">
                <Input type="number" disabled value={index + 1} />
              </div>
              <div className="col-md-7">
                <Input
                  type="text"
                  placeholder="السؤال؟"
                  value={n.text}
                  onChange={(event) =>
                    handleQuestions("add", "reviews", index, event.target.value)
                  }
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="number"
                  disabled
                  className="en-font"
                  readOnly
                  value={
                    n.degree || n.degree == 0
                      ? n.degree
                      : test?.type?.reviews?.degree
                  }
                />
              </div>
              <div className="col">
                <div className="label-header">
                  <label className="custom-label"> </label>
                </div>
                <button
                  className="remove"
                  onClick={() => handleQuestions("remove", "reviews", index)}
                >
                  <DeleteOutlined />
                </button>
              </div>
            </div>
            <div className="row">
              {rates
                ?.find((r) => r.code === 1)
                ?.details?.map((d, i) => (
                  <div className="col" key={i}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        minus(
                          "reviews",
                          index,
                          test?.type?.reviews?.degree,
                          d.name,
                          d.degree
                        )
                      }
                    >
                      {d.name}{" "}
                      <span className="en-font minus-degree">
                        {" - " + d.degree}
                      </span>
                    </button>
                    <button
                      className="btn btn-sm btn-warning mr-2"
                      onClick={() =>
                        undo(
                          "reviews",
                          index,
                          test?.type?.reviews?.degree,
                          d.name,
                          d.degree
                        )
                      }
                    >
                      <UndoOutlined />
                    </button>
                  </div>
                ))}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة المهارات العامة</h6>{" "}
            <span className="pr-2 en-font">
              {" "}
              الحد الاقصي هو {test?.type?.others?.count}
            </span>
          </span>
          {/* <button
            type="button"
            onClick={() => handleAddQuestion("news")}
            className="btn btn-primary form-button ml-3"
          >
            +
          </button> */}
        </div>
        <select className="form-control" onChange={addPublicQuestions}>
          <option value="0">اختر الاسئلة</option>
          {rates
            ?.find((r) => r.code === 3)
            ?.details.map((n, i) => (
              <option key={n.name} value={n.name}>
                {n.name}
              </option>
            ))}
        </select>
        {publicQuest?.map((n, i) => (
          <div className="row align-items-center" key={i}>
            <div className="col-md-1">
              <span>{i + 1}</span>
            </div>
            <div className="col-md-8">
              <Checkbox
                defaultChecked={true}
                onChange={(event) =>
                  handleOthers(
                    i,
                    n.name,
                    test?.type?.others?.degree,
                    event.target.checked
                  )
                }
              >
                {n.name}
              </Checkbox>
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                className="en-font"
                disabled
                readOnly
                value={test?.type?.others?.degree}
              />
            </div>
            <div className="col-md-1">
              <button
                className="remove"
                onClick={() => removePublicQuest(i, n.name)}
              >
                <DeleteOutlined />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة التجويد</h6>
            <span className="pr-2 en-font">
              {" "}
              الحد الاقصي هو {test?.type?.tajweed?.count}
            </span>
          </span>
        </div>
        <select className="form-control" onChange={addTajweedQuestions}>
          <option value="0">اختر الاسئلة</option>
          {rates
            ?.find((r) => r.code === 2)
            ?.details.map((n, i) => (
              <React.Fragment>
                <option
                  className="tajweed-parent"
                  value={n.details.map((f) => f.name).join("-")}
                >
                  {n.name}
                </option>
                {n.details.map((d, i) => (
                  <option className="tajweed-child" value={d.name}>
                    {d.name}
                  </option>
                ))}
              </React.Fragment>
            ))}
        </select>
        {tajweed?.map((n, i) => (
          <div className="row align-items-center" key={i}>
            <div className="col-md-1">
              <span>{i + 1}</span>
            </div>
            <div className="col-md-8">
              <Checkbox
                defaultChecked={true}
                onChange={(event) =>
                  handleTajweed(
                    i,
                    test?.type?.tajweed?.degree,
                    event.target.checked
                  )
                }
              >
                {n.name}
              </Checkbox>
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                className="en-font"
                disabled
                readOnly
                value={test?.type?.tajweed?.degree}
              />
            </div>
            <div className="col-md-1">
              <button className="remove" onClick={() => removeTajweed(i)}>
                <DeleteOutlined />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="pd admin-card">
        <Input
          className="form-control"
          label="ملاحظات"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          type="textarea"
        />
      </div>
      <div className="pd admin-card">
        <label>موعد الاختبار التالى</label>
        <HijriDatePicker
          inputName="hijri_date"
          className="form-control en-font"
          dateFormat="iYYYY/iMM/iDD"
          quickSelect="true"
          onChange={(value) => setNextTestDate(value)}
        />
      </div>
      <div className="pd admin-card">
        <h6> الاخطاء الحفظ</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {newQuestions
              ?.filter((item) => item?.mistakes?.length > 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.text}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h6> الاخطاء المراجعة</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {reviewQuestions
              ?.filter((item) => item?.mistakes?.length > 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.text}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h6> اخطاء التجويد</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {tajweed
              ?.filter((t) => t.degree === 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.name}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h6> الاخطاء المهارات العامة</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {publicQuest
              ?.filter((p) => p.degree === 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.name}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Popconfirm
        title="اذا قمت بالانهاء لن تتمكن من التعديل مرة اخرى"
        onConfirm={() => compeleteTest()}
        okText="موافق"
        cancelText="إلغاء"
      >
        <button
          type="button"
          disabled={btnLoad}
          className="btn btn-secondary form-button ml-3"
        >
          {btnLoad ? (
            <span>
              <LoadingOutlined /> حفظ و إنهاء
            </span>
          ) : (
            "حفظ و إنهاء"
          )}
        </button>
      </Popconfirm>
    </React.Fragment>
  );
};

export default withRouter(UpdateType);
