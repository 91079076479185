import React from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import Input from "../../components/forms/Input";
import { Checkbox } from "antd";

const UpdateType = (props) => {
  // define state
  const [student, setStudent] = React.useState(null);
  const [teacher, setTeacher] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [test, setTest] = React.useState(null);
  const [newQuestions, setNewQuestions] = React.useState([]);
  const [reviewQuestions, setReviewQuestions] = React.useState([]);
  const [tajweed, setTajweed] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [degree, setDegree] = React.useState(0);
  const [publicQuest, setPublicQuest] = React.useState([]);

  React.useEffect(() => {
    getType();
  }, []);

  const getType = async (req, res) => {
    Promise.all([
      await Http.get("testers/tests/" + props.match.params.id),
    ]).then((res) => {
      setStudent(res[0].data.test.student);
      setTeacher(res[0].data.test.teacher);
      setPlan(res[0].data.test.plan);
      setTest(res[0].data.test);
      setNewQuestions(res[0].data.test?.questions?.news);
      setReviewQuestions(res[0].data.test?.questions?.reviews);
      setPublicQuest(res[0].data.test?.questions?.others);
      setTajweed(res[0].data.test?.questions?.tajweed);

      setTotal(res[0].data.test?.total);
      setDegree(res[0].data.test?.degree);
    });
  };

  const getCategory = (category) => {
    switch (category) {
      case 1:
        return "جديد";
      case 2:
        return "إعادة أ";
      case 3:
        return "إعادة ب";

      default:
        break;
    }
  };
  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "مفتوح";
      case 2:
        return "تم التحضير";
      case 3:
        return "منتهي";

      default:
        break;
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <React.Fragment>
      <div className="admin-title">
        <h6>
          الدرجة <span className="en-font main-color">{degree}</span> من{" "}
          <span className="en-font">{total}</span>
          {" - النسبة المؤية "}
          <span className="en-font main-color">
            {test?.appreciation?.ratio}
            {"%"}
          </span>
          {" - التقدير "}
          <span className="main-color">{test?.appreciation?.name}</span>
        </h6>
        <div className="admin-title-super2">
          <button
            to="/admin/tests"
            onClick={goBack}
            className="btn btn-warning"
          >
            العودة الى الاختبارات
          </button>
        </div>
      </div>

      <div className="all-categories admin-card pd">
        <div className="test-info">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center">
              <div className="student-avatar  ml-2">
                <img
                  src={
                    student?.avatar
                      ? Http.front_url + student.avatar
                      : "/img/avatar.png"
                  }
                  alt="student"
                  width="70"
                />
              </div>
              <div className="student-info">
                <h6>
                  <span className="main-color">الطالب:</span>{" "}
                  {student?.fullName}
                </h6>
                <h6>
                  <span className="main-color">المعلم:</span>{" "}
                  {teacher?.fullName}
                </h6>
                <h6>
                  <span className="main-color">الخطة:</span> {plan?.name}
                </h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <h6>
                  <span className="main-color">الاعادة:</span>{" "}
                  {getCategory(test?.category)}
                </h6>
                <h6>
                  <span className="main-color">الحالة:</span>{" "}
                  {getStatus(test?.status)}
                </h6>
                <h6>
                  <span className="main-color">النوع:</span> {test?.type?.name}
                </h6>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <h6>
                  <span className="main-color">التاريخ:</span>{" "}
                  {test?.date?.day + " "}{" "}
                  <span className="en-font">
                    {test?.date?.date + " " + test?.date?.time}
                  </span>
                </h6>
                <h6>
                  <span className="main-color">الحفظ:</span>{" "}
                  {test?.details?.new?.name}
                </h6>
                <h6>
                  <span className="main-color">المراجعة:</span>{" "}
                  {test?.details?.review?.name}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة الحفظ</h6>{" "}
          </span>
        </div>
        {newQuestions.map((n, index) => (
          <React.Fragment key={index}>
            <div className="row align-items-center" key={index}>
              <div className="col-md-1">
                <Input type="number" disabled value={index + 1} />
              </div>
              <div className="col-md-9">
                <Input
                  type="text"
                  readOnly
                  disabled
                  placeholder="السؤال؟"
                  value={n.text}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="number"
                  className="en-font"
                  disabled
                  readOnly
                  value={n.degree ? n.degree : test?.type?.news?.degree}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className="admin-card pd">
        <div className="admin-title">
          <h6>اسئلة المراجعة</h6>
        </div>
        {reviewQuestions.map((n, index) => (
          <React.Fragment key={index}>
            <div className="row align-items-center" key={index}>
              <div className="col-md-1">
                <Input type="number" disabled value={index + 1} />
              </div>
              <div className="col-md-9">
                <Input
                  type="text"
                  readOnly
                  disabled
                  placeholder="السؤال؟"
                  value={n.text}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="number"
                  disabled
                  className="en-font"
                  readOnly
                  value={n.degree ? n.degree : test?.type?.reviews?.degree}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة التجويد</h6>{" "}
          </span>
        </div>
        {tajweed?.map((n, i) => (
          <div className="row align-items-center" key={i}>
            <div className="col-md-1">
              <span>{i + 1}</span>
            </div>
            <div className="col-md-9">
              <Checkbox checked={n.degree ? true : false} disabled readOnly>
                {n.name}
              </Checkbox>
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                className="en-font"
                disabled
                readOnly
                value={n?.degree}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="admin-card pd">
        <div className="admin-title">
          <span className="d-flex align-items-center">
            <h6>اسئلة المهارات العامة</h6>{" "}
          </span>
        </div>
        {publicQuest?.map((n, i) => (
          <div className="row align-items-center" key={i}>
            <div className="col-md-1">
              <span>{i + 1}</span>
            </div>
            <div className="col-md-9">
              <Checkbox checked={n.degree ? true : false} disabled readOnly>
                {n.name}
              </Checkbox>
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                className="en-font"
                disabled
                readOnly
                value={n?.degree}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="pd admin-card">
        <Input
          className="form-control"
          label="ملاحظات"
          disabled
          value={test?.notes}
          readOnly
          type="text"
        />
      </div>
      <div className="pd admin-card">
        <h6> الاخطاء الحفظ</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {newQuestions?.map((m, i) => (
              <tr key={i}>
                <td className="text-right en-font">{i + 1}</td>
                <td className="text-right question-text">{m.text}</td>
                <td className="text-right">{m.degree}</td>
                <td>
                  <span className="all-mistakes">
                    {m.mistakes?.map((mistake) => (
                      <span className="mistake">
                        {`${mistake.name} - `}
                        <span className="en-font">{mistake.count}</span>
                      </span>
                    ))}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h6> الاخطاء المراجعة</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {reviewQuestions?.map((m, i) => (
              <tr key={i}>
                <td className="text-right en-font">{i + 1}</td>
                <td className="text-right question-text">{m.text}</td>
                <td className="text-right">{m.degree}</td>
                <td>
                  <span className="all-mistakes">
                    {m.mistakes?.map((mistake) => (
                      <span className="mistake">
                        {`${mistake.name} - `}
                        <span className="en-font">{mistake.count}</span>
                      </span>
                    ))}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h6> اخطاء التجويد</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {tajweed
              ?.filter((n) => n.degree === 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.name}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h6> الاخطاء المهارات العامة</h6>
        <table className="table mistakes table-bordered mb-5">
          <thead>
            <tr>
              <th scope="col">م</th>
              <th scope="col">السؤال</th>
              <th scope="col">الدرجة</th>
              <th scope="col">الاخطاء</th>
            </tr>
          </thead>
          <tbody>
            {publicQuest
              ?.filter((n) => n.degree === 0)
              .map((m, i) => (
                <tr key={i}>
                  <td className="text-right en-font">{i + 1}</td>
                  <td className="text-right question-text">{m.name}</td>
                  <td className="text-right">{m.degree}</td>
                  <td>
                    <span className="all-mistakes">
                      {m.mistakes?.map((mistake) => (
                        <span className="mistake">
                          {`${mistake.name} - `}
                          <span className="en-font">{mistake.count}</span>
                        </span>
                      ))}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UpdateType);
