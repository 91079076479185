import React from "react";

const Input = ({
  style,
  label,
  type,
  className,
  required,
  disabled,
  readOnly,
  name,
  fOption,
  options,
  value,
  onChange,
  placeholder,
  defaultValue,
  error,
}) => {
  return (
    <div className="form-group">
      <div className="label-header">
        <label className="custom-label" htmlFor={name}>
          {label}{" "}
          {required ? <span className="required required-star">*</span> : ""}
        </label>
        {error && <small className="required">{error}</small>}
      </div>
      {type === "select2" && (
        <select
          style={style}
          required={required}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options
              .filter((c) => c.sub_categories.length > 0)
              .map((parent) => (
                <optgroup key={parent._id} label={parent.name.en}>
                  {parent.sub_categories.map((sub) => (
                    <option value={sub._id} key={sub._id}>
                      {sub.name.en}
                    </option>
                  ))}
                </optgroup>
              ))}
        </select>
      )}
      {type === "normal-select" && (
        <select
          style={style}
          required={required}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option value="0">{fOption}</option>
          {options &&
            options.map((opt, i) => (
              <option value={opt} key={i}>
                {opt}
              </option>
            ))}
        </select>
      )}
      {type === "select" && (
        <select
          style={style}
          required={required}
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          className={`form-control ${className ? className : ""}`}
        >
          <option>{fOption}</option>
          {options &&
            options.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
        </select>
      )}
      {(type === "text" ||
        type === "number" ||
        type === "tel" ||
        type === "password" ||
        type === "date" ||
        type === "color" ||
        type === "email") && (
        <input
          style={style}
          type={type}
          defaultValue={defaultValue}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          className={`form-control ${className ? className : ""}`}
          required={required}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {type === "textarea" 
        && (
        <textarea
          style={style}
          type={type}
          defaultValue={defaultValue}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          className={`form-control ${className ? className : ""}`}
          required={required}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
        ></textarea>
      )}
    </div>
  );
};

export default Input;
