import React from "react";
import { withRouter } from "react-router-dom";
import Http from "../../services/Http";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// import "dayjs/locale/ar";
dayjs.extend(LocalizedFormat);

const UpdateType = (props) => {
  // define state
  const [student, setStudent] = React.useState(null);
  const [teacher, setTeacher] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [test, setTest] = React.useState(null);
  const [newQuestions, setNewQuestions] = React.useState([]);
  const [reviewQuestions, setReviewQuestions] = React.useState([]);
  const [tajweed, setTajweed] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [degree, setDegree] = React.useState(0);
  const [publicQuest, setPublicQuest] = React.useState([]);
  const [supervisor, setSuper] = React.useState("");

  React.useEffect(() => {
    getType();
  }, []);

  const getType = (req, res) => {
    Promise.all([
      Http.get("testers/tests/" + props.match.params.id),
      Http.get("admin/info"),
    ]).then((res) => {
      setStudent(res[0].data.test.student);
      setTeacher(res[0].data.test.teacher);
      setPlan(res[0].data.test.plan);
      setTest(res[0].data.test);
      setNewQuestions(res[0].data.test?.questions?.news);
      setReviewQuestions(res[0].data.test?.questions?.reviews);
      res[0].data.test?.questions?.others?.map((q, i) => (q.sort = i + 1));
      setPublicQuest(res[0].data.test?.questions?.others);
      res[0].data.test?.questions?.tajweed?.map((q, i) => (q.sort = i + 1));
      setTajweed(res[0].data.test?.questions?.tajweed);

      setTotal(res[0].data.test?.total);
      setDegree(res[0].data.test?.degree);

      setSuper(res[1].data.info?.supervisor);

      setTimeout(() => {
        window.print();
      }, 1000);
    });
  };

  const getCategory = (category) => {
    switch (category) {
      case 1:
        return "جديد";
      case 2:
        return "إعادة أ";
      case 3:
        return "إعادة ب";

      default:
        break;
    }
  };
  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "مفتوح";
      case 2:
        return "تم التحضير";
      case 3:
        return "منتهي";

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="all-categories admin-card pd">
        <div className="d-flex align-items-center">
          <div className="logo-container">
            <img src="/img/logo.png" width="80" className="logo" alt="karama" />
          </div>
          <div className="w-100">
            <h6
              className="mb-0 text-center font-weight-bold"
              style={{ marginRight: -65 }}
            >
              الدرجة <span className="en-font main-color">{degree}</span> من{" "}
              <span className="en-font">{total}</span>
              {" - النسبة المؤية "}
              <span className="en-font main-color">
                {test?.appreciation?.ratio}
                {"%"}
              </span>
              {" - التقدير "}
              <span className="main-color">{test?.appreciation?.name}</span>
            </h6>
          </div>
        </div>
        <h6 className="text-center">بيانات الاختبار</h6>{" "}
        <div className="test-info">
          <table>
            <tr rowSpan={2}>
              <th className="td-sm">الطالب:</th>
              <td className="td-print">{student?.fullName}</td>
              <th className="td-sm">المعلم:</th>
              <td className="td-print">{teacher?.fullName}</td>
            </tr>
            <tr rowSpan={2}>
              <th className="td-sm">الخطة:</th>
              <td className="td-print">{plan?.name}</td>
              <th className="td-sm">الحالة:</th>
              <td className="td-print">{getCategory(test?.category)}</td>
            </tr>

            <tr rowSpan={2}>
              <th className="td-sm">النوع:</th>
              <td className="td-print">{test?.type?.name}</td>
              <th className="td-sm">التاريخ:</th>
              <td className="td-print">
                {" "}
                {test?.date?.day + " "}{" "}
                <span className="en-font">
                  {test?.date?.date + " " + test?.date?.time}
                </span>
              </td>
            </tr>
            <tr rowSpan={2}>
              <th className="td-sm">الحفظ:</th>
              <td className="td-print">{test?.details?.new?.name}</td>
              <th className="td-sm">المراجعة:</th>
              <td className="td-print">{test?.details?.review?.name}</td>
            </tr>
          </table>
        </div>
      </div>

      {newQuestions.length > 0 && (
        <div className="admin-card pd test-info">
          <div className="admin-title">
            <span className="d-flex align-items-center">
              <h6>اسئلة الحفظ</h6>{" "}
            </span>
          </div>
          <table>
            <tr>
              <th className="td-sm">م</th>
              <th className="td-md">السؤال</th>
              <th className="td-lg">الاخطاء</th>
              <th className="td-sm">الدرجة</th>
            </tr>
            {newQuestions.map((n, index) => (
              <tr key={n._id}>
                <td className="td-sm">{index + 1}</td>
                <td className="td-md">{n.text}</td>
                <td className="td-lg">
                  <span className="all-mistakes">
                    {n.mistakes?.map((mistake) => (
                      <span style={{ fontSize: 13 }} className="mistake">
                        {`${mistake.name} - `}
                        <span className="en-font">{mistake.count}</span>
                      </span>
                    ))}
                  </span>
                </td>
                <td className="td-sm">{n.degree}</td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {reviewQuestions.length > 0 && (
        <div className="admin-card pd test-info">
          <div className="admin-title">
            <h6>اسئلة المراجعة</h6>
          </div>
          <table>
            <tr>
              <th className="td-sm">م</th>
              <th className="td-md">السؤال</th>
              <th className="td-lg">الاخطاء</th>
              <th className="td-sm">الدرجة</th>
            </tr>
            {reviewQuestions.map((n, index) => (
              <tr key={n._id}>
                <td className="td-sm">{index + 1}</td>
                <td className="td-md">{n.text}</td>
                <td className="td-lg">
                  <span className="all-mistakes">
                    {n.mistakes?.map((mistake) => (
                      <span style={{ fontSize: 13 }} className="mistake">
                        {`${mistake.name} - `}
                        <span className="en-font">{mistake.count}</span>
                      </span>
                    ))}
                  </span>
                </td>
                <td className="td-sm">{n.degree}</td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {tajweed.length > 1 && (
        <div className="admin-card pd test-info">
          <div className="admin-title">
            <span className="d-flex align-items-center">
              <h6>اسئلة التجويد</h6>
            </span>
          </div>
          <div className="two-tables">
            <table className="half float-right">
              <tr>
                <th className="td-sm">م</th>
                <th className="th-print">السؤال</th>
                <th className="td-sm">الدرجة</th>
              </tr>
              {tajweed
                .filter((item, i) => i % 2 == 0)
                .map((n) => (
                  <tr key={n._id}>
                    <td className="td-sm">{n.sort}</td>
                    <td style={{ fontSize: 15 }} className="td-print">
                      {n.name}
                    </td>
                    <td className="td-sm">{n.degree}</td>
                  </tr>
                ))}
            </table>
            <table className="half float-left">
              <tr>
                <th className="td-sm">م</th>
                <th className="th-print">السؤال</th>
                <th className="td-sm">الدرجة</th>
              </tr>
              {tajweed
                .filter((item, i) => i % 2 != 0)
                .map((n) => (
                  <tr key={n._id}>
                    <td className="td-sm">{n.sort}</td>
                    <td style={{ fontSize: 15 }} className="td-print">
                      {n.name}
                    </td>
                    <td className="td-sm">{n.degree}</td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      )}

      {publicQuest.length > 1 && (
        <div className="admin-card pd test-info">
          <div className="admin-title">
            <span className="d-flex align-items-center">
              <h6>اسئلة المهارات العامة</h6>{" "}
            </span>
          </div>
          <div className="two-tables">
            <table className="half float-right">
              <tr>
                <th className="td-sm">م</th>
                <th className="td-lg">السؤال</th>
                <th className="td-sm">الدرجة</th>
              </tr>
              {publicQuest
                .filter((item, i) => i % 2 == 0)
                .map((n) => (
                  <tr key={n._id}>
                    <td className="td-sm">{n.sort}</td>
                    <td className="td-lg">{n.name}</td>
                    <td className="td-sm">{n.degree}</td>
                  </tr>
                ))}
            </table>
            <table className="half float-left">
              <tr>
                <th className="td-sm">م</th>
                <th className="td-lg">السؤال</th>
                <th className="td-sm">الدرجة</th>
              </tr>
              {publicQuest
                .filter((item, i) => i % 2 != 0)
                .map((n) => (
                  <tr key={n._id}>
                    <td className="td-sm">{n.sort}</td>
                    <td className="td-lg">{n.name}</td>
                    <td className="td-sm">{n.degree}</td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      )}

      {tajweed.length === 1 && publicQuest.length === 1 && (
        <div className="admin-card pd test-info">
          <div className="row">
            <div className="col-md-6">
              <div className="admin-title">
                <span className="d-flex align-items-center">
                  <h6>اسئلة التجويد</h6>
                </span>
              </div>
              <div className="">
                <table className="half float-right">
                  <tr>
                    <th className="td-sm">م</th>
                    <th className="th-print">السؤال</th>
                    <th className="td-sm">الدرجة</th>
                  </tr>
                  {tajweed.map((n) => (
                    <tr key={n._id}>
                      <td className="td-sm">{n.sort}</td>
                      <td style={{ fontSize: 15 }} className="td-print">
                        {n.name}
                      </td>
                      <td className="td-sm">{n.degree}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>

            <div className="col-md-6">
              <div className="admin-title">
                <span className="d-flex align-items-center">
                  <h6>اسئلة المهارات العامة</h6>{" "}
                </span>
              </div>
              <div className="">
                <table className="half">
                  <tr>
                    <th className="td-sm">م</th>
                    <th className="td-lg">السؤال</th>
                    <th className="td-sm">الدرجة</th>
                  </tr>
                  {publicQuest.map((n) => (
                    <tr key={n._id}>
                      <td className="td-sm">{n.sort}</td>
                      <td className="td-lg">{n.name}</td>
                      <td className="td-sm">{n.degree}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="admin-card pd test-info">
        {test?.notes && test?.notes.trim().length && (
          <div style={{ marginBottom: 10 }}>
            <h6>ملاحظات</h6>
            <p
              style={{ fontSize: 13, whiteSpace: "break-spaces" }}
              className="mb-0"
            >
              {test?.notes}
            </p>
          </div>
        )}
        <h6 className="text-center">
          تم الانتهاء الاختبار فى
          {test?.endDate ? dayjs(test.endDate).format("YYYY-MM-DD h:mm A") : ""}
        </h6>
      </div>

      <div className="pd signatures">
        <div className="single-signature">
          <h6 className="text-center font-weight-bold">توقيع المختبر</h6>
          <h6 className="text-center">{test?.tester?.fullName}</h6>
        </div>
        <div className="single-signature">
          <h6 className="text-center font-weight-bold">توقيع المشرف العام</h6>
          <h6 className="text-center">{supervisor}</h6>
        </div>
        <div className="single-signature">
          <h6 className="text-center font-weight-bold">توقيع المعلم</h6>
          <h6 className="text-center">{teacher?.fullName}</h6>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UpdateType);
