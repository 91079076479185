import React from "react";
import { Route } from "react-router-dom";
import { isExpire } from "./admin";

const ProtectRoute = ({ path, component: Component }) => {
  const GoToPage = () => {
    window.location.replace("//tajalkarama.com");
  };

  return (
    <Route
      path={path}
      render={() => {
        if (!localStorage.getItem("tester_token") || isExpire()) {
          return <GoToPage />;
        }
        return <Component />;
      }}
    />
  );
};

export default ProtectRoute;
