import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { message, Table, Tooltip, Modal, Radio } from "antd";
import Http from "../../services/Http";
import HijriDatePicker from "../../helpers/hijri-date-picker/src/lib";
import ContentLoader from "../../components/loaders/ContentLoader";
import {
  FileOutlined,
  PullRequestOutlined,
  SendOutlined,
  FieldTimeOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import moment from "moment-hijri";
import queryString from "query-string";
import { Select } from "antd";
moment.locale("ar-SA");

const { Option } = Select;

class AllRecords extends Component {
  state = {
    records: [],
    load: false,
    name: "",
    id: 0,
    selected: false,
    testers: [],
    tester: false,
    delays: false,
    requests: false,
    move: false,
    visible: false,
    smallLoad: false,
    dateModal: false,
    date: false,
    time: false,
    totalPages: 0,
    page: 1,
    status: 1,
    pageSize: 50,
  };

  componentDidMount() {
    if (
      !queryString.parse(this.props.location.search)["page"] ||
      !queryString.parse(this.props.location.search)["pageSize"] ||
      !queryString.parse(this.props.location.search)["status"]
    )
      this.changeSearch();
    else {
      this.getData();
      this.getTesterTimes();
      this.getStudnets();
    }
  }

  componentDidUpdate() {
    if (
      this.state.page !=
        queryString.parse(this.props.location.search)["page"] ||
      this.state.pageSize !=
        queryString.parse(this.props.location.search)["pageSize"] ||
      this.state.status !=
        queryString.parse(this.props.location.search)["status"] ||
      (queryString.parse(this.props.location.search)["name"] &&
        this.state.name !=
          queryString.parse(this.props.location.search)["name"])
    )
      this.getData();
  }

  getData = () => {
    this.setState({ load: false });

    const page =
      queryString.parse(this.props.location.search)["page"] ?? this.state.page;
    const pageSize =
      queryString.parse(this.props.location.search)["pageSize"] ??
      this.state.pageSize;
    const status =
      queryString.parse(this.props.location.search)["status"] ??
      this.state.status;
    const name =
      queryString.parse(this.props.location.search)["name"] ?? this.state.name;

    this.setState({ page, pageSize, status, name }, () => {
      Http.get(
        `testers/tests${
          this.props.location.search
            ? this.props.location.search
            : `?status=1&page=1&pagesize=50`
        }`
      ).then(async (res) => {
        res.data.tests.map((r) => (r.key = r._id));
        this.setState({
          totalPages: res.data.totalRecords,
          records: res.data.tests,
          load: true,
        });
      });
    });
  };

  getTesterTimes = () => {
    Http.get("testers/info").then(async (res) => {
      let times = [];

      res.data.tester.times.map((time) => {
        if (time.times) {
          time.times.map((t) => {
            times.push(`${time.day} ${t.text}`);
          });
        }
      });
      this.setState({
        tester: res.data.tester,
        times,
      });
    });
  };

  getStudnets = () => {
    Http.get("testers/students").then(async (res) => {
      this.setState({
        students: res.data.students,
      });
    });
  };

  changeSearch = (
    page = this.state.page,
    pageSize = this.state.pageSize,
    status = this.state.status,
    name = this.state.name
  ) => {
    if (name) {
      this.props.history.push(
        `/tests?status=${status}&page=${page}&pageSize=${pageSize}&name=${name}`
      );
    } else
      this.props.history.push(
        `/tests?status=${status}&page=${page}&pageSize=${pageSize}`
      );
  };

  getTest = (id) => {
    Http.get(`testers/tests/${id}`).then(async (res) => {
      this.setState({
        test: res.data.test,
      });
    });
  };

  deleteRecord = (id) => {
    Http.delete("testers/tests/" + id)
      .then((res) => {
        this.getData();
        message.success(res.data.message);
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  changeDelayRequest = (status) => {
    Http.patch("testers/tests/" + this.state.selected + "/requestDelay", {
      status: status,
    })
      .then((res) => {
        message.success(res.data.message);
        this.setState({ visible: false, selected: false, requestDelay: false });
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  changeTester = () => {
    Http.patch("testers/tests/" + this.state.selected + "/change-tester", {
      tester: this.newTester.value,
    })
      .then((res) => {
        message.success(res.data.message);
        this.setState({ move: false, selected: false });
        this.getData();
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  changeDate = () => {
    Http.patch("testers/tests/" + this.state.selected + "/change-date", {
      date: {
        date: window.selectedDate,
        day: window.selectedTime.split(" ")[0],
        time: window.selectedTime
          .split(" ")
          .filter((item, i) => i !== 0)
          .join(" "),
      },
    })
      .then((res) => {
        message.success(res.data.message);
        this.setState({ dateModal: false, selected: false });
        this.getData();
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  getTesters = async () => {
    const { data } = await Http.get(
      "testers/testers?test=" + this.state.selected
    );
    this.setState({ testers: data.testers });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  getCategory = (category) => {
    switch (category) {
      case 1:
        return "جديد";
      case 2:
        return "إعادة أ";
      case 3:
        return "إعادة ب";

      default:
        break;
    }
  };
  getStatus = (status) => {
    switch (status) {
      case 1:
        return "مفتوح";
      case 2:
        return "تم التحضير";
      case 3:
        return "منتهي";

      default:
        break;
    }
  };
  getStatusDelay = (status) => {
    switch (status) {
      case 1:
        return "تحت المراجعة";
      case 2:
        return "تم الموافقة";
      case 3:
        return "تم الرفض";

      default:
        break;
    }
  };

  render() {
    const { records, load, smallLoad } = this.state;

    const columns = [
      {
        title: "التاريخ",
        dataIndex: "date",
        key: "date",
        render: (text) => (
          <span className="en-font">
            {`${text?.day} - ${text?.date} - ${text?.time}`}
          </span>
        ),
      },
      {
        title: "الطالب",
        dataIndex: "student",
        key: "student",
        render: (text) => <span className="en-font">{text?.fullName}</span>,
      },
      {
        title: "المعلم",
        dataIndex: "teacher",
        key: "teacher",
        render: (text) => <span className="en-font"> {text?.fullName}</span>,
      },

      {
        title: "إعادة",
        dataIndex: "category",
        key: "category",
        render: (text) => (
          <span className="en-font">{this.getCategory(text)}</span>
        ),
      },

      {
        title: "النوع",
        dataIndex: "type",
        key: "type",
        render: (text) => <span className="en-font">{text?.name}</span>,
      },
      {
        title: "الخطة",
        dataIndex: "plan",
        key: "plan",
        render: (text) => <span className="en-font">{text?.name}</span>,
      },

      {
        title: "الحالة",
        dataIndex: "status",
        key: "status",
        render: (text) => <span>{this.getStatus(text)}</span>,
      },

      {
        title: "إجراءات",
        key: "action",
        render: (text, record) => (
          <span>
            {record.status === 1 && (
              <Tooltip title="تعديل">
                <Link
                  to={`/tests/${record._id}/edit`}
                  className={`btn btn-sm btn-warning`}
                >
                  <FileOutlined />
                </Link>
              </Tooltip>
            )}
            {record.status === 2 && (
              <Tooltip title="مشاهدة">
                <Link
                  to={`/tests/${record._id}/show`}
                  className={`btn btn-sm btn-secondary`}
                >
                  <FileOutlined />
                </Link>
              </Tooltip>
            )}
            {record.status === 2 && (
              <Tooltip title="طباعة">
                <Link
                  to={`/tests/${record._id}/print`}
                  className={`btn btn-sm btn-info`}
                >
                  <PrinterOutlined />
                </Link>
              </Tooltip>
            )}
            {record.requestDelay && (
              <Tooltip title="طلب التأجيل">
                <button
                  className={`btn btn-sm btn-primary`}
                  onClick={() =>
                    this.setState({
                      visible: true,
                      requestDelay: record.requestDelay,
                      selected: record._id,
                    })
                  }
                >
                  <PullRequestOutlined />
                </button>
              </Tooltip>
            )}
            {record.status === 1 && (
              <Tooltip title="تحويل الاختبار">
                <button
                  className={`btn btn-sm btn-info`}
                  onClick={() => {
                    this.setState(
                      {
                        move: true,
                        selected: record._id,
                      },
                      () => {
                        this.getTesters();
                      }
                    );
                  }}
                >
                  <SendOutlined />
                </button>
              </Tooltip>
            )}
            {record.status === 1 && (
              <Tooltip title="تغيير الوقت">
                <button
                  className={`btn btn-sm btn-success`}
                  onClick={() => {
                    this.setState({
                      dateModal: true,
                      test: record.date,
                      selected: record._id,
                    });
                  }}
                >
                  <FieldTimeOutlined />
                </button>
              </Tooltip>
            )}

            {/* <Tooltip title="حذف">
              <Popconfirm
                title="هل تريد تأكيد الحذف؟"
                onConfirm={() => this.deleteRecord(record._id)}
                okText="موافق"
                cancelText="إلغاء"
              >
                <button className="btn btn-sm btn-danger">
                  <MdDelete />
                </button>
              </Popconfirm>
            </Tooltip> */}
          </span>
        ),
      },
    ];

    const Details = () => {
      return (
        <Modal
          title="تفاصيل اليوم"
          visible={this.state.visible}
          onOk={() =>
            this.setState({
              visible: false,
              requestDelay: false,
              selected: false,
            })
          }
          onCancel={() =>
            this.setState({
              visible: false,
              requestDelay: false,
              selected: false,
            })
          }
          footer={[
            <button
              key="submit"
              type="primary"
              className="btn btn-primary ml-2"
              onClick={() => this.changeDelayRequest(2)}
            >
              قبول
            </button>,
            <button
              key="submit"
              type="primary"
              className="btn btn-danger"
              onClick={() => this.changeDelayRequest(3)}
            >
              رفض
            </button>,
            <button
              key="submit"
              type="primary"
              className="btn btn-secondary"
              onClick={() =>
                this.setState({ visible: false, requestDelay: false })
              }
            >
              اغلاق
            </button>,
          ]}
        >
          <div className="one-details mb-2">
            <label className="main-color">الحالة</label>
            <div className="btn-secondary p-2">
              {this.getStatusDelay(this.state.requestDelay?.status)}
            </div>
          </div>
          <div className="one-details">
            <label className="main-color">التاريخ الجديد</label>
            <div>{this.state.requestDelay?.date}</div>
          </div>
          <hr />
          <div className="one-details">
            <label className="main-color">الوقت المحدد</label>
            <div>{`${this.state.requestDelay?.day} - ${this.state.requestDelay?.time}`}</div>
          </div>
          <hr />
          <div className="one-details">
            <label className="main-color">الاسباب</label>
            <div>{this.state.requestDelay?.notes}</div>
          </div>
        </Modal>
      );
    };

    const DateDetails = () => {
      return (
        <Modal
          title="تغيير موعد الاختبار"
          visible={this.state.dateModal}
          onOk={() =>
            this.setState({
              dateModal: false,
              selected: false,
            })
          }
          onCancel={() =>
            this.setState({
              dateModal: false,
              selected: false,
            })
          }
          footer={[
            <button
              key="submit"
              type="button"
              className="btn btn-primary ml-2"
              onClick={() => this.changeDate()}
            >
              تأكيد
            </button>,

            <button
              key="submit"
              type="primary"
              className="btn btn-secondary"
              onClick={() =>
                this.setState({ dateModal: false, selected: false })
              }
            >
              اغلاق
            </button>,
          ]}
        >
          <div className="one-details mb-2">
            <label className="main-color">التاريخ</label>
            <div>
              <span className="en-font">
                {`${this.state.test?.day} - ${this.state.test?.date} - ${this.state.test?.time}`}
              </span>
            </div>
            <br />

            <HijriDatePicker
              inputName="hijri_date"
              className="form-control en-font"
              dateFormat="iYYYY/iMM/iDD"
              quickSelect="true"
              onChange={(value) => (window.selectedDate = value)}
            />

            <label className="main-color">الوقت</label>
            <Radio.Group
              options={this.state.times}
              onChange={(event) => (window.selectedTime = event.target.value)}
              optionType="button"
            />
          </div>
        </Modal>
      );
    };

    const ChangeTesterForm = () => {
      return (
        <Modal
          title="تحويل الاختبار الى مختبر آخر"
          visible={this.state.move}
          onOk={() =>
            this.setState({
              move: false,
              selected: false,
            })
          }
          onCancel={() =>
            this.setState({
              move: false,
              selected: false,
            })
          }
          footer={[
            <button
              key="submit"
              type="primary"
              className="btn btn-primary ml-2"
              onClick={() => this.changeTester()}
            >
              تأكيد
            </button>,
            <button
              key="submit"
              type="primary"
              className="btn btn-secondary"
              onClick={() =>
                this.setState({ move: false, selected: false, tester: false })
              }
            >
              اغلاق
            </button>,
          ]}
        >
          <div className="one-details">
            <label className="main-color">المختبر الجديد</label>
            <div>
              <select
                className="form-control"
                ref={(newTester) => (this.newTester = newTester)}
              >
                {this.state.testers.map((tester) => (
                  <option key={tester._id} value={tester._id}>
                    {tester.fullName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal>
      );
    };

    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>الاختبارات</h6>
          <div className="filters d-flex">
            {/* <select
              className="form-control ml-2"
              value={this.state.name}
              onChange={(event) =>
                this.changeSearch(1, 50, this.state.status, event.target.value)
              }
              style={{ width: 250 }}
            >
              <option>اختر الطالب</option>
              {this.state.students?.map((item) => (
                <option value={item._id}>{item.fullName}</option>
              ))}
            </select> */}
            <Select
              showSearch
              style={{ width: 200, marginLeft: 20 }}
              className="select-student"
              placeholder="اختر الطالب"
              optionFilterProp="children"
              onChange={(value) =>
                this.changeSearch(1, 50, this.state.status, value)
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.students?.map((item) => (
                <Option value={item._id}>{item.fullName}</Option>
              ))}
            </Select>
            <select
              className="form-control"
              value={this.state.status}
              onChange={(event) => this.changeSearch(1, 50, event.target.value)}
              style={{ width: 250 }}
            >
              <option value={1}>جارية</option>
              <option value={2}>منتهية</option>
            </select>
          </div>
          {smallLoad && <ContentLoader size={20} />}
        </div>

        <Details />
        <ChangeTesterForm />
        <DateDetails />

        {load && (
          <Table
            columns={columns}
            dataSource={records}
            size="small"
            pagination={{
              pageSize: this.state.pageSize,
              current: Number(this.state.page),
              hideOnSinglePage: true,
              total: this.state.totalPages,
              showSizeChanger: false,
              onChange: (current) => this.changeSearch(current),
              onShowSizeChange: (current, size) =>
                this.changeSearch(this.state.page, size),
            }}
          />
        )}

        {!load && <ContentLoader />}
      </div>
    );
  }
}

export default withRouter(AllRecords);
