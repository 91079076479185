import React, { Component } from "react";
import { message } from "antd";
import Http from "../services/Http";
import ContentLoader from "../components/loaders/ContentLoader";

class Setting extends Component {
  state = {
    info: null,
    load: false,
    password: false,
    smallLoad: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("testers/info").then(async (res) => {
      this.setState({
        info: res.data.tester,
        load: true,
      });
    });
  };

  changePassword = () => {
    if (this.state.password) {
      Http.patch("testers/change-password", { password: this.state.password })
        .then((res) => {
          message.success(res.data.message);
        })
        .catch((error) => message.warning(error.response.data.message));
    } else message.success("ليس هناك تغييرات فى كلمة المرور");
  };

  render() {
    const { info, load } = this.state;

    return (
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>بيانات المختبر</h6>
        </div>

        {!load && <ContentLoader />}

        {load && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4 form-group">
                <label>الاسم</label>
                <input
                  className="form-control"
                  type="text"
                  value={info.fullName}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-md-4 form-group">
                <label>رقم الهوية</label>
                <input
                  className="form-control en-font"
                  type="text"
                  value={info.identityNumber}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-md-4 form-group">
                <label>رقم الهاتف</label>
                <input
                  className="form-control en-font"
                  type="text"
                  value={info.phone}
                  disabled
                  readOnly
                />
              </div>
              <div className="col-md-12 form-group">
                <label>كلمة المرور</label>
                <input
                  className="form-control en-font"
                  type="password"
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
              </div>
            </div>
            <button className="btn btn-primary" onClick={this.changePassword}>
              تعديل كلمة المرور
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Setting;
