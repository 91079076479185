/**
 * Main App
 */
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import ProtectRoute from "./services/ProtectRoute";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";

// admin layout
import AdminSidebar from "./components/layouts/adminSidebar/Sidebar";
// config file
import { config } from "./helpers/config";
// import firebase from "./firebase";
// pages
import Statistics from "./pages/home";
import Settings from "./pages/setting";
import Login from "./pages/login";

import Tests from "./pages/tests/";
import UpdateTest from "./pages/tests/update";
import PrintTest from "./pages/tests/print";
import ShowTest from "./pages/tests/show";

import UpdateTimes from "./pages/testers/edit";

class App extends React.Component {
  componentDidMount() {
    if (window.location.pathname.includes("login")) {
      ReactDOM.render(<Login />, document.getElementById("root"));
    }

    // init firebase messages
    // const messaging = firebase.messaging();
    // Notification.requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then((token) => {
    //     console.log(token);
    //   });
  }
  render() {
    return (
      <div className={`admin-layout dir-${config.lang}`} id="admin-layout">
        {/* login page */}
        <Switch>
          <Route exact path="/login" component={Login} />
        </Switch>
        {/* other pages */}
        <AdminSidebar />
        <div className="admin-wrapper">
          <div className="container-fluid">
            <Switch>
              <Redirect exact path="/" to="/tests" component={Tests} />
              <ProtectRoute exact path="/statistics" component={Statistics} />
              <ProtectRoute exact path="/setting" component={Settings} />

              {/* Tests routers */}
              <ProtectRoute exact path="/tests/:id/show" component={ShowTest} />

              <ProtectRoute
                exact
                path="/tests/:id/edit"
                component={UpdateTest}
              />
              <ProtectRoute
                exact
                path="/tests/:id/print"
                component={PrintTest}
              />
              <ProtectRoute exact path="/tests" component={Tests} />
              <ProtectRoute exact path="/times" component={UpdateTimes} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
