import React, { Component } from "react";
import Http from "../services/Http";
import { Alert } from "antd";
import { message } from "antd";

class adminLogin extends Component {
  state = {
    username: "",
    password: "",
    errors: "",
  };

  login = (event) => {
    event.preventDefault();

    // Validation
    if (this.state.username.length <= 0)
      message.warning("حقل اسم المستخدم فارغ");
    else if (this.state.password.length <= 0)
      message.warning("حقل اسم كلمة المرور فارغ");
    else {
      const { username, password } = this.state;
      let user = {
        username,
        password,
      };
      Http.post("testers/login", user)
        .then((res) => {
          message.success(res.data.message);
          localStorage.setItem("tester_token", res.data.token);
          setTimeout(() => {
            window.location.replace("/tests");
          }, 300);
        })
        .catch((error) => {
          console.log(error);
          message.warning(error.response.data.message);
        });
    }
  };

  getData = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;
    return (
      <section className="adminLogin login-page">
        <div className="container">
          {errors.length > 0 && (
            <Alert
              message="Errors"
              description={<p> {errors + "\n"} </p>}
              type="error"
            />
          )}
          <br /> <br />
          <div className="login-form">
            <img src="/img/logo.png" className="login-logo" alt="karama" />
            <form className="form-login-admin" onSubmit={this.login}>
              <div className="form-group">
                <label htmlFor="">اسم المستخدم</label>
                <input
                  type="text"
                  name="username"
                  required
                  onChange={this.getData}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="">كلمة المرور</label>
                <input
                  type="password"
                  name="password"
                  required
                  onChange={this.getData}
                  className="form-control"
                />
              </div>
              <button type="submit" className="submit-btn main-bg">
                تأكيد
              </button>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default adminLogin;
