import jwt from "jsonwebtoken";

export function isExpire() {
  const token = localStorage.getItem("tester_token");
  const admin = jwt.decode(token, "secret");

  if (Date.now() >= admin.exp * 1000) {
    return true;
  }
}

export function isCan(model, action) {
  const token = localStorage.getItem("tester_token");
  const admin = jwt.decode(token, "secret");

  if (admin) {
    const roles = admin.role.models;

    const target = roles.find((r) => r.name === model);

    if (target) {
      if (model === "pages") {
        if (target.ids.find((p) => p === action)) return true;
        else return false;
      } else {
        if (target.actions.find((p) => p === action)) return true;
        else return false;
      }
    }
  }
}
