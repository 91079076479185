import React, { Component } from "react";

import { IconContext } from "react-icons";
import Http from "../services/Http";

class Statics extends Component {
  state = { load: false };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("testers/statistics").then((res) => {
      this.setState({ data: res.data.statistics, load: true });
    });
  };
  render() {
    const { data, load } = this.state;
    return (
      <div className="all-statics">
        <div className="row">
          {load && (
            <IconContext.Provider value={{ size: "1.5em" }}>
              <div className="col-md-3">
                <div className="admin-card statics-card bg-color5">
                  <h2 className="en-font statics-numbers">{data.admins}</h2>
                  <h5>المشرفين</h5>
                </div>
              </div>

              <div className="col-md-3 ">
                <div className="admin-card statics-card bg-green">
                  <h2 className="en-font statics-numbers">{data.students}</h2>
                  <h5>الطلاب</h5>
                </div>
              </div>
              <div className="col-md-3">
                <div className="admin-card statics-card bg-orange">
                  <h2 className="en-font statics-numbers">{data.teachers}</h2>
                  <h5>المعلمين</h5>
                </div>
              </div>
              <div className="col-md-3">
                <div className="admin-card statics-card bg-color1">
                  <h2 className="en-font statics-numbers">{data.testers}</h2>
                  <h5>المختبرين</h5>
                </div>
              </div>
              <div className="col-md-3">
                <div className="admin-card statics-card bg-color2">
                  <h2 className="en-font statics-numbers">{data.tests}</h2>
                  <h5>الاختبارات</h5>
                </div>
              </div>

              <div className="col-md-3">
                <div className="admin-card statics-card bg-color4">
                  <h2 className="en-font statics-numbers">{data.plans}</h2>
                  <h5>الخطط التعليمية</h5>
                </div>
              </div>
            </IconContext.Provider>
          )}
        </div>
      </div>
    );
  }
}

export default Statics;
