import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import App from "./App";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DayjsUtils}>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

unregisterServiceWorker();
