import React from "react";
import { Link, withRouter } from "react-router-dom";
import Http from "../../services/Http";
import { formatAMPM } from "../../helpers/dateFormat";
import { LoadingOutlined, DeleteFilled } from "@ant-design/icons";
import Input from "../../components/forms/Input";
import { openNoti } from "../../components/confirmation-popup/Notification";
import { Select } from "antd";
import { TimePicker } from "@material-ui/pickers";
import dayjs from "dayjs";

const EditRecord = (props) => {
  // const [username, setUserName] = React.useState("");
  const [times, setTimes] = React.useState([]);
  const [tester, setTester] = React.useState({});
  const [btnLoad, setBtnLoad] = React.useState(false);
  const { Option } = Select;

  // get plans & tester data
  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    Http.get("testers/info").then(async (res) => {
      if (res.data.tester.times?.length) setTimes(res.data.tester.times);
      setTester(res.data.tester);
    });
  };

  const removeTime = (time, index) => {
    let clone = times.length ? [...times] : [];
    clone[time].times = clone[time].times.filter((t, i) => i !== index);

    setTimes(clone);
  };

  const addTime = (t, index) => {
    let clone = [...times];
    clone[index].times.push({
      start: new Date(),
      end: new Date(),
      text: "",
    });
    setTimes(clone);
  };

  const setMiniTime = (value, type, time, index) => {
    let clone = [...times];

    if (type === "start") {
      clone[time].times[index]["startDate"] = value;
      clone[time].times[index][type] = formatAMPM(new Date(value));
    }

    if (type === "end") {
      clone[time].times[index]["endDate"] = value;
      clone[time].times[index][type] = formatAMPM(new Date(value));

      clone[time].times[index].text =
        clone[time].times[index].start + " - " + clone[time].times[index].end;
    }

    setTimes(clone);
  };

  // update new record
  const updateRecord = async (event) => {
    event.preventDefault();
    setBtnLoad(true);

    // Update new category
    const record = {
      times,
    };

    await Http.patch("testers/times/", record)
      .then(async (result) => {
        openNoti("success", result.data.message);

        setBtnLoad(false);
      })
      .catch((err) => {
        setBtnLoad(false);
      });
  };

  return (
    <React.Fragment>
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>
            الاوقات المتاحة{" "}
            <span className="main-color font-weight-bold">
              {tester?.fullName}
            </span>
          </h6>
          <div className="admin-title-super2">
            <button
              type="submit"
              disabled={btnLoad}
              onClick={updateRecord}
              className="btn btn-primary form-button"
            >
              {btnLoad ? (
                <span>
                  <LoadingOutlined /> تعديل
                </span>
              ) : (
                "تعديل"
              )}
            </button>
          </div>
        </div>
      </div>
      {/* times */}
      <div className="all-categories admin-card pd">
        <div className="admin-title">
          <h6>الاوقات المتاح بها المختبر</h6>
        </div>
        <div className="available-times">
          {times.map((t, index) => (
            <React.Fragment key={index}>
              <div className="times-title mb-4">
                <h6>{t.day}</h6>
                <button
                  className="btn btn-secondary form-button"
                  onClick={() => addTime(t, index)}
                >
                  إضافة وقت
                </button>
              </div>
              <div className="row" key={index}>
                {t.times.map((item, i) => (
                  <div className="col-md-4">
                    <div className="time-from-to" key={i}>
                      <span
                        className="remove-time"
                        onClick={() => removeTime(index, i)}
                      >
                        <DeleteFilled />
                      </span>
                      <div className="one-time">
                        <span className="pl-3">من</span>
                        <TimePicker
                          value={dayjs(item.startDate)}
                          onChange={(value) =>
                            setMiniTime(value, "start", index, i)
                          }
                        />
                      </div>
                      <div className="one-time">
                        <span className="pl-3">إلى</span>
                        <TimePicker
                          value={dayjs(item.endDate)}
                          onChange={(value) =>
                            setMiniTime(value, "end", index, i)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditRecord);
