import React from "react";
import { IconContext } from "react-icons";
import {
  MdDashboard,
  MdPeople,
  MdPowerSettingsNew,
  MdSettings,
} from "react-icons/md";
import { Link } from "react-router-dom";
import "./style.scss";

const AdminSidebar = () => {
  // toggle sidebar
  const sidebarToggle = () => {
    let windowWidth = window.innerWidth;
    let sidebar = document.getElementById("admin-layout");

    if (windowWidth < 991) sidebar.classList.toggle("scenario2");
  };

  const isActive = (name) => {
    return window.location.href.includes(name) ? true : false;
  };

  return (
    <aside className="admin-sidebar main-bg" id="admin-sidebar">
      <div className="logo-container">
        <img src="/img/logo.png" className="logo" alt="karama" />
      </div>
      <IconContext.Provider
        value={{
          color: "white",
          className: "icon-class-name",
          size: "1.2em",
        }}
      >
        <ul className="nav flex-column">
          {/* <li
            className={`nav-item ${isActive("statistics") ? "active" : ""}`}
            onClick={sidebarToggle}
          >
            <Link className="nav-link" title="dashboard" to="/">
              <MdDashboard />
              <h6>الاحصائيات</h6>
            </Link>
          </li> */}

          <li
            className={`nav-item ${isActive("tests") ? "active" : ""}`}
            onClick={sidebarToggle}
          >
            <Link
              className="nav-link"
              title="tests"
              to="/tests?status=1&page=1&pageSize=50"
            >
              <MdPeople />
              <h6>الاختبارات</h6>
            </Link>
          </li>

          <li
            className={`nav-item ${isActive("times") ? "active" : ""}`}
            onClick={sidebarToggle}
          >
            <Link className="nav-link" title="times" to="/times">
              <MdPeople />
              <h6>الاوقات المتاحة</h6>
            </Link>
          </li>

          <li
            className={`nav-item ${isActive("setting") ? "active" : ""}`}
            onClick={sidebarToggle}
          >
            <Link className="nav-link" to="/setting">
              <MdSettings />
              <h6>الاعدادات</h6>
            </Link>
          </li>

          <li className="nav-item">
            <div
              className="nav-link"
              onClick={() => {
                localStorage.removeItem("tester_token");
                window.location.replace("/login");
              }}
            >
              <MdPowerSettingsNew />
              <h6>تسجيل خروج</h6>
            </div>
          </li>
        </ul>
      </IconContext.Provider>
    </aside>
  );
};

export default AdminSidebar;
